header {
	background: $white;
	.cell {
		background: $white url("../img/header.jpg") no-repeat top left;
		height: 105px;
		background-size: contain;

		@include breakpoint(medium) {
			height: 210px;
		}

		@include breakpoint(large) {
			height: 290px;
		}

		@include breakpoint(xlarge) {
			height: 340px;
		}

		@include breakpoint(xxlarge) {
			height: 375px;
		}

		h1 {
			text-indent: -5000px;
		}
	}
}