// PAGE Styling

.page {
	margin-top: 2em !important;
	margin-bottom: 2em !important;
	position: relative;

	.grid-x {
		align-items : flex-start;		
	}
}
