body {
	overflow-x: hidden;

	h1.page-title {
		text-transform: capitalize;
	}

	h3 {
		margin-top: 1em;
	}

	.button {
		text-decoration: none;
	}

	#sharing {
		padding: 1em 1em 1em 0;
		border-top: 1px solid $medium-gray;
	}


	.image-left {
		float: left;
		margin-right: 2em;
		margin-bottom: 1em;
		width: 60%;
	}


	.footnote {
		font-size: calc(14px);
		margin-top: 2em;
	}

	cite {
		margin-top: 1em;
		font-size: calc(14px);
		font-style: normal;
	}

	blockquote {
		background: #f9f9f9;
		border-left: 10px solid #ccc;
		margin: 1.5em 0;
		padding: 2em;
		quotes: "\201C""\201D""\2018""\2019";
		font-style: italic;

		&:before {
			color: #ccc;
			content: open-quote;
			font-size: 4em;
			line-height: 0.1em;
			margin-right: 0.25em;
			vertical-align: -0.4em;
		}

		&:after {
			color: #ccc;
			content: close-quote;
			font-size: 4em;
			line-height: 0.1em;
			margin-right: 0.25em;
			vertical-align: -0.4em;
			float: right;
		}
	}

	table {
		tbody tr:nth-child(even) {
			background: #f2f2f2;
		}

		tr {
			border: 1px solid $medium-gray;
			background: #f2f2f2;
		}

		.button {
			margin: 0;
			width: 100%;
			background: $warning-color;

			&:hover {
				background: scale-color($warning-color, $lightness: -15%);
			}

			&:focus {
				background: $warning-color;			
			}
		}
	}

	#sharing {
		p {
			font-size: calc(14px);
		}
	}

	.orbit-caption {
		background-color: rgba(0, 0, 0, 0.7) !important;

		a {
			color: white;
			font-size: 1.2em;

			&.small {
				font-size: 1em;
			}
			&.no-underline {
				text-decoration: none;
			}
		}
	}

	ul.social {

		li {
			margin-right: 0.5em;
			a {
				padding: 0.6rem;
				&.facebook {
					background: #4267B2;
				}
				&.twitter {
					background: #1DA1F2;
				}
				&.youtube {
					background: #FF0000;
				}
				&.linkedin {
					background: #0073b1;
				}
				&.web {
					background: $primary-color;
				}
				&.instagram {
					background: $blue;
				}
			}
		}
	}
}


