// NAVIGATION Styling

.navigation {

	padding-right: 0 !important;
	padding-left: 0 !important;

	@include breakpoint(large) {
		padding-right: $global-padding !important;
		padding-left: $global-padding !important;
	}

	.title-bar {
		background: $secondary-color;
	}
	
	.top-bar {
		position: relative;
		padding: 0;

		&:before {
			background-color: $secondary-color;
			width: 100%;
			height: 100%;			
			left: -100%;
			position: absolute;
			top: 0;			
			content: " ";
		}

		&:after {
			background-color: $secondary-color;
			width: 100%;
			height: 100%;			
			right: -100%;
			position: absolute;
			top: 0;			
			content: " ";
		}

		.top-bar-left {

			ul.menu {

				li {
					&:last-child {
						background: $warning-color;
					}
					a {
						color: $white;
						text-transform: uppercase;
						padding: 1em;

						&:hover {
							background-color: $primary-color;
						}
					}

					&.active.last {
						a {
							background: $warning-color;
						}
						
					}
				}
			}
		}

		.top-bar-right {
			ul.social { 
				padding: 1em; 

				@include breakpoint(medium) {
					padding: 0; 
				}

				li {
					margin-right: 0.5em;
					a {
						padding: 0.6rem;
						&.facebook {
							background: #4267B2;
						}
						&.twitter {
							background: #1DA1F2;
						}
						&.youtube {
							background: #FF0000;
						}
						&.linkedin {
							background: #0073b1;
						}
					}
				}
			}
		}
	}
}
