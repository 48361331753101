// PAGES Specific Styling

// INDEX

body.index {
	aside {
		a.button {
			background: $primary-color;
			text-decoration: underline;
		}
	}
}

// PROGRAMME

body.programme {

	a.button.pdf {
		float: right;
	}

	.day {

		margin-top: 3em;
		clear: both;

		ul.programme {
			>
			li {
				margin: 2em 0;

				h2 {
					color: $white;
					font-size: calc(20px);
					background: $pink;
					font-weight: 400;
					padding: 0.8em 0.8em 0.8em 1em;
					margin: 0 0 0 3em;
					display: inline-block;
					width: calc(100% - 2em);
				}

				.time {
					display: block;
					float: left;
					background: $blue;
					color: $white;
					padding: 0.5em;
					font-weight: bold;
					margin-right: 1em;
					margin-left: -$global-margin*5;
				}

				.title {
					display: block;
					float: left;
					width: 85%;
				}

				.info {
					display: block;
					margin-top: 1em;
					margin-left: 4em;
				}
			}
		}
	}
}


// SPEAKERS

ul.speakers-list {
	margin: 0;
	list-style: none;

	li {
		margin-bottom: 4rem;

		.image {
			position: relative;

			&:before {
				position: absolute;
				top: -0.5rem;
				left: -0.5rem;
				content: "";
				background: $warning-color;
				width: 50%;
				height: 50%;
				z-index: 0;
			}

			&:after {
				position: absolute;
				bottom: -0.5rem;
				right: -0.5rem;
				content: "";
				background: $primary-color;
				width: 50%;
				height: 50%;
				z-index: 0;
			}

			img {
				position: relative;
				z-index: 10;
			}
		}

		.info {
			padding: 0 1rem 1rem 3rem;
			h2.name {	
				font-size: calc(26px);
				float: left;
				background: $green;
				color: $white;
				padding: 0.5rem;
			}

			.job {
				clear: both;
				font-size: calc(22px);
			}

			.bio {
				font-size: calc(15px);

				p {
					margin-bottom: 0.3rem;
				}

				ul {
					li {
						margin-bottom: 0.3rem;
					}
				}
			}
		}

		p.contacts {
			margin-top: 1rem;
			margin-bottom: 0.5rem;
		}	

		ul.social {
			list-style: none;
			margin: 0;

			li {
				display: block;
				float: left;
				margin-bottom: 0;

				a {
					line-height: 1;
				}
			}
		}
	}
}


// PARTICIPATE - UNCONFERENCE

body.participate,
body.unconference,
body.info,
body.resources {

	h2.title {
		color: $white;
		font-size: calc(20px);
		background: $warning-color;
		font-weight: 400;
		padding: 0.8em 0.8em 0.8em 1em;
		margin: 1.5em 0 0.5em 0;		
	}
}

body.unconference {
	aside .content a.button {
		background: $warning-color;
	}
}




// TAKE ACTION

body.action {

	.button {
		background: $pink;

		&.share {
			width: 8.5em;
		}
	}
}