/* @group FONT ICONS MIXIN */

/// For adding font icons to elements using CSS pseudo-elements.
/// Attach a particular icon to an element complete with all the required styles.
/// Attach just the required icon styles to a bunch of elements, without setting a particular icon.
/// Set or swap the icon on an element without applying required styles.
/// Set custom styles for your icon pseudo-element.
///
/// @example
///   @include icon(before, search, $white, true, 1rem);
///
/// @param {type} $position - Before or after icon position within the text
/// @param {type} $icon - Map icon name
/// @param {type} $color [#ccc]  - Color
/// @param {type} $styles [true] - Icon Font styles
/// @param {type} $size [1rem] - Icon Font size
/// @link http://jaydenseric.com/blog/fun-with-sass-and-font-icons

@mixin icon($position: before, $icon: false, $color: #ccc, $styles: true, $size: 1rem){
	@if $position == both{
		$position: 'before, &:after';
	}
	// Either a :before or :after pseudo-element, or both, defaulting to :before
	&:#{$position}{
		vertical-align: middle;
		@if $icon{
			// A particular icon has been specified
			content: '#{map-get($icons, $icon)}';
			font-size: $size;
		}
		@if $styles{
			font-family: 'etf-skills';
			font-weight: normal;
			font-style: normal;
			color: $color;
			// Supportive icon styles required

			speak: none;
		}
		// Include any extra rules supplied for the pseudo-element
		@content;
	}
}



.social a {
	display: inline-block;
	font-size: 0px;
	@include icon(before, icon, $white, true, 0.9rem);
}


@each $name, $code in $icons {
	.social a[class*="#{$name}"] {
		color: transparent;
		&:before {
		content: $code;
		}
	}
}
