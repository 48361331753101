// ASIDE Styling

aside {
	background: $green;
	padding: $global-padding*2.5;
	margin-top: 2em;
	
	.content {
		font-size: calc(15px);

		.item {
			background: $white;
			margin-bottom: 	$global-margin;

			&.image {
				p {
					padding: 0.5rem;
					font-weight: bold;
				}
			}
		}

		.text {
			padding: $global-padding*2.5;
		}

		.image {
			.text {
				padding-top: $global-padding;
				padding-bottom: $global-padding;
			}
		}

		a.button {
			width: 100%;
			background: $pink;
		}
	}

	@include breakpoint(large) {
		margin-left: -2em;
	}
}
