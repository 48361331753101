//* @group IMPORT FONT *//

@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700');



//* @group IMPORT ICON FONT *//

@font-face {
	font-family: 'etf-skills';
	src: url('../fonts/etf-skills.svg') format('svg');
	src: url('../fonts/etf-skills.eot');
	src: url('../fonts/etf-skills.eot') format('embedded-opentype'),
		 url('../fonts/etf-skills.woff2') format('woff2'),
		 url('../fonts/etf-skills.ttf') format('truetype'),
		 url('../fonts/etf-skills.woff') format('woff');   
		  
	font-weight: normal;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: subpixel-antialiased;
}



/* @group ICONS MAP */

$icons: (

	pdf:			'\e900',
	facebook:		'\e901',
	twitter:		'\e902',
	youtube:		'\e903',
	linkedin:		'\e904',
	web: 			'\e905',
	instagram:		'\e906',
);