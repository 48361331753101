// MAIN Styling

.main {
	background: $white;
	padding: $global-padding*2.5;

	@include breakpoint(large) {
		padding: $global-padding*2.5 $global-padding*6 $global-padding*2.5 $global-padding*2.5;		
	}
}
