// FOOTER Styling

footer {
	background: $secondary-color;
	padding: $global-padding 0;

	p{
		color: $white;
		font-size: calc(12px);
		margin: 0;
	}
	
	.menu {
		margin-top: 0.7em;
		@include breakpoint(medium) {
			float: right;
			margin-top: 0;
		}
		a {
			color: $white;
			padding-top: 0;
			padding-left: 0;
			padding-bottom: 0;
			text-decoration: underline !important;
			font-size: calc(14px);
		}
	}

	.sponsors {
		margin-top: 1rem;

		.sponsors-list {
			margin: 0;

			li {
				margin-bottom: 0.5rem;
				@include breakpoint(large) {
				display: block;
				float: left;
				margin-right: 0.5rem;
				}
			}
		}
	}
}
